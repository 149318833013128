import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { CgTranscript } from "react-icons/cg";
import { Rating } from '@smastrom/react-rating';
import { BounceLoader } from 'react-spinners';
import ConsoleHelper from '../../utils/consoleHelper';
import { RESUME_STATUS } from '../../utils/constants';
import { useAuth } from '../../contextProviders/AuthContext';
import ReportService from '../../services/report.service';
import { useToast } from '../../contextProviders/ToastContext';
import CircularProgress from '../../components/Report/CircularProgress';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { capitalizeFirstLetterOfEachWord, convertUTCToLocal, getRating, secondsToMinutes } from '../../utils/common';

export default function ReportTechnicalScreening({report}){
    const {user} = useAuth();
    const videoRef = useRef(null);
    const {showErrorMessage} = useToast();
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showAnomaly, setShowAnomaly] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);

    const technicalScreening = (report && report?.technical_screening) ? report?.technical_screening[0] : report ? report : {};
    const audioAnalysis = technicalScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_01')[0];
    const primarySkills  = (technicalScreening?.primary_skills && Object.keys(technicalScreening?.primary_skills || {}).length) ? technicalScreening?.primary_skills : {};
    const secondarySkills  = (technicalScreening?.secondary_skills && Object.keys(technicalScreening?.secondary_skills || {}).length) ? technicalScreening?.secondary_skills : {};

    if(Object.keys(primarySkills).length){
        if(primarySkills['NA']) delete primarySkills['NA']
        if(primarySkills['na']) delete primarySkills['na']
    }
    if(Object.keys(secondarySkills).length){
        if(secondarySkills['NA']) delete secondarySkills['NA'] 
        if(secondarySkills['na']) delete secondarySkills['na']
    }

    const timestampToSeconds = (timestamp) => {
      const match = timestamp.match(/(\d{2}):(\d{2}):(\d{2}),(\d{3})/);
      if (!match) {
        throw new Error('Invalid timestamp format');
      }
  
      const [, hours, minutes, seconds, milliseconds] = match.map(Number);
      return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    };

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getReport(report['id'] || technicalScreening['id']);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
        }
    }

    if(!technicalScreening || (typeof technicalScreening == 'object' &&  Object.keys(technicalScreening).length === 0)){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    report?.status['technicalScreening'] === RESUME_STATUS.COMPLETED ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'
                    } 
                </h1>
            </div>
        )
    }

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    const showAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(true);
    const hideAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(false);

    const handleSeek = (timestamp) => {
        try {
            const seconds = timestampToSeconds(timestamp);
            if (videoRef.current) {
                videoRef.current.seekTo(Math.floor(seconds), 'seconds');
                setIsPlaying(true);
            }
        } catch (error) {
          ConsoleHelper.error(`Error seeking video: ${JSON.stringify(error)}`);
        }
    };

    function getAnomalyAndSummary(){
        if(!report?.anomalies)
            return {anomaly: null, summary: null}

        const data = report?.anomalies?.replace('Interview Anomalies Data', '');
        const [anomaly, summary] = data.split('### Summary:');
        return { anomaly, summary}
    }

    return (
        <div className='flex flex-col gap-10'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            <div className={`h-screen w-screen absolute top-0 left-0 z-50 flex justify-center items-center bg-transparent ${showAnomaly ? 'visible' : 'hidden'}`}>
                <div className="flex justify-center items-center w-[70%] xl:w-1/2 relative">
                    <div className="h-9 w-9 rounded-full bg-white absolute -top-4 shadow-md z-20 -right-2 text-primary flex justify-center items-center cursor-pointer">
                        <MdClear size={22} onClick={() => setShowAnomaly(false)} />
                    </div>
                    <div className={`bg-white rounded-md w-full overflow-hidden relative flex flex-col ${getAnomalyAndSummary()?.anomaly ? 'visible' : 'hidden'}`}>
                        <div className="p-5 py-4 sticky top-0 shadow-md">
                            <h1 className='text-xl font-semibold text-primary'>
                                Anomalies detected
                            </h1>
                        </div>
                        <div className="flex p-5 overflow-y-auto max-h-[500px]"> {/* Add max-h and overflow-y-auto */}
                            <CustomReactMarkdown data={getAnomalyAndSummary()?.anomaly} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    <ReactPlayer
                        ref={videoRef}
                        controls
                        pip={false}
                        playing={isPlaying}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        className="react-player flex-1"
                        url={technicalScreening?.video_path}
                    />
                    <div className='flex flex-col gap-4 items-start flex-1'>
                        <div className='flex gap-5 items-center'>
                            <CircularProgress
                                size={75}
                                strokeWidth={9} 
                                score={parseFloat(report?.total_score).toFixed(1)}
                            />
                            <div className='flex flex-col gap-4 py-10 items-center w-full'>
                                <div className='flex flex-col gap-1 items-center justify-center'>
                                    <Rating 
                                        readOnly
                                        color='#B99000' 
                                        cancel={false} 
                                        style={{width: 120}}
                                        value={getRating(report?.total_score, user?.['organization']).value} 
                                    />
                                    <span className='font-semibold text-xs'>{getRating(report?.total_score, user?.['organization']).value} out of 5 stars</span>
                                </div>
                                <h2 className='text-sm text-primary font-semibold'>{getRating(report?.total_score, user?.['organization']).title}</h2>
                            </div>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${technicalScreening?.start_time ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold w-40'>Date of the interview :</h3>
                            <span className=''>{technicalScreening?.start_time ? convertUTCToLocal(technicalScreening?.start_time) : 'Not captured' }</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold w-40'>Duration:</h3>
                            <span className=''>{technicalScreening?.duration ? secondsToMinutes(technicalScreening?.duration) : 'NA'}</span>
                        </div>
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Tab switches count:</h3>
                            <span className=''>{technicalScreening?.anomalies_data?.tab_switches_count}</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Full Screen Exits Count:</h3>
                            <span className=''>{technicalScreening?.anomalies_data?.full_screen_exits_count}</span>
                        </div> */}
                        {/* <div className='flex gap-2 text-primary'>
                            <h3 className='font-semibold'>Anomalies detected:</h3>
                            <ReactMarkdown>{technicalScreening?.anomalies}</ReactMarkdown>
                        </div> */}
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Facial Expression:</h3>
                            <span className=''>{technicalScreening?.anomalies_data?.facial_expression}</span>
                        </div> */}
                        <div className='flex items-center gap-2 text-primary'>
                            <p className='font-semibold'>Transcript of the interview</p>
                            <CgTranscript 
                                size={28}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div>
                        <button
                            disabled={loading}
                            onClick={extractReport} 
                            className='border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                            {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                        </button>
                    </div>
                </div>
                {showTranscript && <TranscriptComponent id={technicalScreening?.id} hide={() => setShowTranscript(false)}/>}
                {technicalScreening?.summary && (
                    <div>
                        <h4 className='text-xl font-bold'>Summary</h4>
                        <p className='text-primary my-5'>{technicalScreening?.summary}</p>
                    </div>
                )}
            </div>
            <div className={`${report?.anomalies_status ? 'visible': 'hidden'} flex flex-col gap-5 text-primary`}>
                <div className='flex items-center gap-10'>
                    <h2 className='text-xl font-bold'>Anomalies</h2>
                    <button 
                        onClick={() => setShowAnomaly(true)} 
                        className={`text-sm cursor-pointer font-semibold text-blue ${getAnomalyAndSummary()?.anomaly  && !report['anomalies']?.toLowerCase().includes('not found') ? 'visible': 'hidden'}`}>
                        View more
                    </button>
                </div>
                <p className={`${report?.anomalies_status?.status === 'red'? 'text-dr': report?.anomalies_status?.status === 'green'? 'text-dg': 'text-do'} font-medium`}>
                    {report?.anomalies_status?.description}
                </p>
                <div className={`${getAnomalyAndSummary()?.summary != null ? 'visible': 'hidden'} flex items-start gap-2 text-primary`}>
                    <h3 className='font-semibold'>Summary:</h3>
                    <CustomReactMarkdown data={getAnomalyAndSummary()?.summary}/>
                </div>
            </div>
            <div className={`flex flex-col ${Object.keys(primarySkills).length ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Primary Skills</h2>
                <ul className='flex gap-10 flex-wrap mt-5'>
                    {Object.keys(primarySkills || {}).map((item, index) => {
                        const object = primarySkills[item];
                        const score = parseInt(object.score);
                        return (
                            <li 
                                className='w-[30%]'
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='font-bold'>{capitalizeFirstLetterOfEachWord(item)}</h5>
                                    <div className={`flex flex-col  justify-center items-center ${score <= 0 ? 'visible': 'hidden'}`}>
                                        <span className='font-semibold text-xs'>NA</span>
                                    </div>
                                    <div className={`flex flex-col justify-center items-center ${score > 0 ? 'visible': 'hidden'}`}>
                                        <Rating 
                                            readOnly
                                            color='#B99000' 
                                            cancel={false} 
                                            style={{width: 120}}
                                            value={score/20} 
                                        />
                                        <span className='font-semibold text-xs'>{score / 20} out of 5 stars</span>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold text-sm'>Reason:</h5>
                                    <p className='pb-3'>{object.reason}</p>
                                    <div className={`flex flex-col gap-3 ${technicalScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {technicalScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${Object.keys(secondarySkills).length ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Secondary Skills</h2>
                <ul className='flex gap-10 flex-wrap mt-5'>
                    {Object.keys(secondarySkills || {}).map((item, index) => {
                        const object = secondarySkills[item];
                        const score = parseInt(object.score);
                        return (
                            <li
                                className='w-[30%]'
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='font-bold'>{capitalizeFirstLetterOfEachWord(item)}</h5>
                                    <div className={`flex flex-col  justify-center items-center ${score <= 0 ? 'visible': 'hidden'}`}>
                                        <span className='font-semibold text-xs'>NA</span>
                                    </div>
                                    <div className={`flex flex-col  justify-center items-center ${score > 0 ? 'visible': 'hidden'}`}>
                                        <Rating 
                                            readOnly
                                            color='#B99000' 
                                            cancel={false} 
                                            style={{width: 120}}
                                            value={score/20} 
                                        />
                                        <span className='font-semibold text-xs'>{score / 20} out of 5 stars</span>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold'>Reason:</h5>
                                    <p className='pb-3'>{object.reason}</p>
                                    <div className={`flex flex-col gap-3 ${technicalScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {technicalScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex gap-5 items-center'>
                        <CircularProgress
                            size={60}
                            strokeWidth={7} 
                            score={parseFloat(audioAnalysis?.score).toFixed(1)}
                        />
                        <div className={`flex items-center flex-col gap-2 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRating(audioAnalysis?.score, user?.['organization']).value} 
                            />
                            <span className='font-semibold text-xs'>{getRating(audioAnalysis?.score, user?.['organization']).value} out of 5 stars</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${technicalScreening['candidate_feedback'] && technicalScreening['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={technicalScreening['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{technicalScreening['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}